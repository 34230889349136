import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12 23 0 17l4.9-2.5L0 12l4.9-2.5L0 7l12-6 12 6-4.9 2.5L24 12l-4.9 2.5L24 17l-12 6zm-7.6-6 7.7 3.9 7.6-3.9-2.8-1.4L12 18l-4.9-2.5L4.4 17zm0-5 7.7 3.9 7.6-3.9-2.8-1.4-4.9 2.5-4.9-2.5L4.4 12zm0-5 7.7 3.9L19.7 7 12 3.2 4.4 7z" />
  </svg>
);
export default SvgComponent;
