import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import s from './Nav.module.scss';
import { routesConfig, UserRole } from '../../routes/routesConfig';

const userRole: UserRole =
  (localStorage.getItem('userRole') as UserRole) || 'admin';

const Nav = () => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const toggleDropdown = (key: string) => {
    setOpenDropdown((prev) => (prev === key ? null : key));
  };

  const closeDropdowns = () => setOpenDropdown(null);

  return (
    <nav className={s.wrapper}>
      <ul className={s.list}>
        {Object.entries(routesConfig)
          .filter(([_, route]) => route.roles.includes(userRole))
          .map(([key, route]) => {
            const { name, path, icon: Icon, children } = route;
            const isActive = children?.some(
              (child) => window.location.pathname === child.path,
            );
            return (
              <li key={key} className={s.item}>
                {children ? (
                  <>
                    <button
                      className={classNames(s.button, { [s.active]: isActive })}
                      onClick={() => toggleDropdown(key)}
                    >
                      <span className={s.icon}>
                        <Icon />
                      </span>
                      <span className={s.text}>{key}</span>
                    </button>
                    {openDropdown === key && (
                      <ul className={s.dropdown}>
                        {children.map((child) => (
                          <li key={child.path} className={s.subItem}>
                            <NavLink
                              to={child.path}
                              className={({ isActive }) =>
                                classNames(s.link, { [s.active]: isActive })
                              }
                            >
                              <span className={s.text}>{child.name}</span>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <NavLink
                    to={path}
                    className={({ isActive }) =>
                      classNames(s.link, { [s.active]: isActive })
                    }
                    onClick={closeDropdowns}
                  >
                    <span className={s.icon}>
                      <Icon />
                    </span>
                    <span className={s.text}>{name}</span>
                  </NavLink>
                )}
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default Nav;
