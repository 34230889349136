import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M9.5 10.8C8.1 8.9 6.2 7.3 4.1 6.1c-.3-.2-.7-.3-1.1-.3-.8 0-1.6.5-2 1.2-.6 1.1-.2 2.5.9 3.1 3.3 1.8 5.5 5.2 5.7 9.1 0-.4 0-.9.2-1.3 0-.1.1-.1.1-.2C8.9 16 9.5 14 9.5 12v-1.2M22.6 6.5c-.4-1.8-1-3.5-1.8-5.1C20.4.5 19.5 0 18.5 0c-.4 0-.7.1-1.1.2-1.3.6-1.8 2.1-1.2 3.3 1.3 2.7 1.9 5.5 1.9 8.5 0 2.9-.6 5.8-1.9 8.4 0 0-.2.5-.2 1 0 .2 0 .4.1.6.2.7.6 1.4 1.4 1.7.3.3.7.3 1.1.3.9 0 1.8-.5 2.2-1.3.5-.9 1-2.2 1.4-3.8.1-.5.3-.9.4-1.4.4-1.8.6-3.6.6-5.5 0-1.8-.2-3.7-.6-5.5" />
    <path d="M12 3.9c0-.1-.1-.1-.1-.2l-.6-.6c-.4-.2-.8-.4-1.3-.4-.4 0-.6.1-.9.2-.3.1-.6.3-.9.6-.3.3-.4.6-.5.9-.2.6-.2 1.4.2 2 .8 1.3 1.3 2.9 1.5 4.4.1.4.1.8.1 1.3 0 2-.5 4-1.6 5.7 0 .1-.1.1-.1.2-.2.4-.3.9-.2 1.3.1.7.5 1.4 1.1 1.8.2.1.4.2.5.2.2.1.5.1.7.1.8 0 1.6-.4 2-1.1 1.3-2.2 2.1-4.6 2.2-7.1v-1c.2-3.1-.6-5.9-2.1-8.3" />
    <path d="M16.3 20.4c1.3-2.6 1.9-5.5 1.9-8.4 0-1-.1-1.7-.1-2.2-1.6-2.6-4-5-6.8-6.7 0 0 .1 0 .1.1.2.1.3.3.5.5 0 .1.1.1.1.2 1.5 2.5 2.3 5.3 2.3 8.1v1c1.2 2.3 1.8 4.8 1.8 7.5v.8c0-.3.1-.6.2-.9 0 .1 0 0 0 0" />
  </svg>
);
export default SvgComponent;
