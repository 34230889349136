import React from 'react';
import s from './Layout.module.scss';
import logoImage from '../assets/img/logo.gif';
import Nav from '../components/Nav/Nav';
import Header from '../components/Header/Header';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className={s.wrapper}>
      <aside className={s.aside}>
        <a className={s.logo}>
          <span className={s.logo__img}>
            <img src={logoImage} alt="Interma" />
          </span>
          <span className={s.logo__text}>Interma</span>
        </a>
        <Nav />
      </aside>
      <section className={s.section}>
        <Header />
        <main className={s.main}>
          <Outlet />
        </main>
      </section>
    </div>
  );
};

export default Layout;
