// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nav_wrapper__IRRfw {
  width: 100%;
}

.Nav_list__aWH6\\+ {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.Nav_icon__VYOFr {
  margin-right: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Nav_icon__VYOFr svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  fill: var(--color-primary);
}

.Nav_text__yl5YM {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--color-primary);
  text-transform: capitalize;
}

.Nav_link__HOTcf, .Nav_button__7mapl {
  padding: 0.75rem 2rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.Nav_link__HOTcf.Nav_active__3h2eF .Nav_text__yl5YM, .Nav_button__7mapl.Nav_active__3h2eF .Nav_text__yl5YM {
  color: var(--color-active);
}
.Nav_link__HOTcf.Nav_active__3h2eF .Nav_icon__VYOFr svg, .Nav_button__7mapl.Nav_active__3h2eF .Nav_icon__VYOFr svg {
  fill: var(--color-active);
}

.Nav_link__HOTcf {
  text-decoration: none;
}

.Nav_button__7mapl {
  border: none;
  background-color: transparent;
}

.Nav_dropdown__HSwb6 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Nav/Nav.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;;AAIA;EACE,SAAA;EACA,UAAA;EACA,qBAAA;AADF;;AAQA;EACE,qBAAA;EACA,aAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AALF;AAOE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,0BAAA;AALJ;;AASA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,2BAAA;EACA,0BAAA;AANF;;AAUA;EACE,qBAAA;EACA,eAAA;EACA,oBAAA;EACA,mBAAA;AAPF;AAWI;EACE,0BAAA;AATN;AAYI;EACE,yBAAA;AAVN;;AAeA;EACE,qBAAA;AAZF;;AAeA;EACE,YAAA;EACA,6BAAA;AAZF;;AAeA;EACE,SAAA;EACA,UAAA;EACA,qBAAA;AAZF","sourcesContent":["@import \"./../../assets/styles/pxToRem.scss\";\n\n.wrapper {\n  width: 100%;\n}\n\n.list {\n  margin: 0;\n  padding: 0;\n  list-style-type: none;\n}\n\n.item {\n\n}\n\n.icon {\n  margin-right: rem(12px);\n  width: rem(24px);\n  height: rem(24px);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  svg {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    object-position: center;\n    fill: var(--color-primary);\n  }\n}\n\n.text {\n  font-size: rem(16px);\n  font-weight: 400;\n  line-height: rem(24px);\n  color: var(--color-primary);\n  text-transform: capitalize;\n}\n\n\n.link, .button {\n  padding: rem(12px) rem(32px);\n  cursor: pointer;\n  display: inline-flex;\n  align-items: center;\n\n  &.active {\n\n    .text {\n      color: var(--color-active);\n    }\n\n    .icon svg {\n      fill: var(--color-active);\n    }\n  }\n}\n\n.link {\n  text-decoration: none;\n}\n\n.button {\n  border: none;\n  background-color: transparent;\n}\n\n.dropdown {\n  margin: 0;\n  padding: 0;\n  list-style-type: none;\n}\n\n.subItem {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Nav_wrapper__IRRfw`,
	"list": `Nav_list__aWH6+`,
	"icon": `Nav_icon__VYOFr`,
	"text": `Nav_text__yl5YM`,
	"link": `Nav_link__HOTcf`,
	"button": `Nav_button__7mapl`,
	"active": `Nav_active__3h2eF`,
	"dropdown": `Nav_dropdown__HSwb6`
};
export default ___CSS_LOADER_EXPORT___;
