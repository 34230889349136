// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_wrapper__5xF9h {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Layout_logo__9HZKQ {
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Layout_logo__img__6w0Qe {
  margin-right: 0.5rem;
  width: 3.375rem;
  height: 3.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Layout_logo__img__6w0Qe img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.Layout_logo__text__LfAOS {
  font-size: 2rem;
  font-weight: 500;
  line-height: 4.5rem;
  letter-spacing: 0.5px;
  color: var(--color-active);
}

.Layout_aside__yLuae {
  width: 12.78%;
  height: 100%;
  min-width: 15.3125rem;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.05);
}

.Layout_section__fN6Gt {
  width: 87.22%;
  height: 100%;
  max-width: calc(100% - 15.3125rem);
}

.Layout_main__oFpTt {
  padding: 1.5rem;
  width: 100%;
  height: calc(100% - 5.625rem);
  background-color: #fcfcff;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/Layout/Layout.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,aAAA;AADF;;AAIA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AADF;;AAIA;EACE,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;AADJ;;AAKA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,qBAAA;EACA,0BAAA;AAFF;;AAKA;EACE,aAAA;EACA,YAAA;EACA,qBAAA;EACA,kDAAA;AAFF;;AAKA;EACE,aAAA;EACA,YAAA;EACA,kCAAA;AAFF;;AAKA;EACE,eAAA;EACA,WAAA;EACA,6BAAA;EACA,yBAAA;EAEA,kBAAA;AAHF","sourcesContent":["@import \"../assets/styles/pxToRem.scss\";\n\n.wrapper {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n}\n\n.logo {\n  padding: rem(4px);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.logo__img {\n  margin-right: rem(8px);\n  width: rem(54px);\n  height: rem(54px);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    object-position: center;\n  }\n}\n\n.logo__text {\n  font-size: rem(32px);\n  font-weight: 500;\n  line-height: rem(72px);\n  letter-spacing: 0.5px;\n  color: var(--color-active);\n}\n\n.aside {\n  width: 12.78%;\n  height: 100%;\n  min-width: rem(245px);\n  box-shadow: 0 rem(4px) rem(8px) 0 rgba(0, 0, 0, 0.05);\n}\n\n.section {\n  width: 87.22%;\n  height: 100%;\n  max-width: calc(100% - rem(245px));\n}\n\n.main {\n  padding: rem(24px);\n  width: 100%;\n  height: calc(100% - rem(90px));\n  background-color: #fcfcff;\n\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Layout_wrapper__5xF9h`,
	"logo": `Layout_logo__9HZKQ`,
	"logo__img": `Layout_logo__img__6w0Qe`,
	"logo__text": `Layout_logo__text__LfAOS`,
	"aside": `Layout_aside__yLuae`,
	"section": `Layout_section__fN6Gt`,
	"main": `Layout_main__oFpTt`
};
export default ___CSS_LOADER_EXPORT___;
