import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12 1a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM8.5 6.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Z"
      clipRule="evenodd"
    />
    <path d="M8 14a6 6 0 0 0-6 6v2a1 1 0 1 0 2 0v-2a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v2a1 1 0 1 0 2 0v-2a6 6 0 0 0-6-6H8Z" />
  </svg>
);
export default SvgComponent;
