import { Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { routesConfig, UserRole } from './routesConfig';
import Loader from '../components/Loader/Loader';

const userRole: UserRole =
  (localStorage.getItem('userRole') as UserRole) || 'admin';

const PrivateRoute = ({
  component: Component,
  allowedRoles,
}: {
  component: React.ComponentType;
  allowedRoles: UserRole[];
}) => {
  return allowedRoles.includes(userRole) ? (
    <Component />
  ) : (
    <Navigate to="/" replace />
  );
};

const generateRoutes = (routes: typeof routesConfig) => {
  return Object.values(routes).flatMap(
    ({ path, component, roles, children }) => {
      const baseRoute = {
        path,
        element: <PrivateRoute component={component} allowedRoles={roles} />,
      };
      if (children) {
        return [
          baseRoute,
          ...children.map(
            ({
              path: childPath,
              component: childComponent,
              roles: childRoles,
            }) => ({
              path: childPath,
              element: (
                <PrivateRoute
                  component={childComponent}
                  allowedRoles={childRoles}
                />
              ),
            }),
          ),
        ];
      }
      return baseRoute;
    },
  );
};

const routes = generateRoutes(routesConfig);

const withSuspense = (Component: React.ComponentType) => (
  <Suspense fallback={<Loader />}>
    <Component />
  </Suspense>
);

export default function Router() {
  const element = useRoutes([
    {
      element: <Layout />,
      children: routes.map(({ path, element }) => ({
        path,
        element: withSuspense(() => element),
      })),
    },
  ]);
  return element;
}
