import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 -1 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0-2a6 6 0 1 1 0 12A6 6 0 0 1 8 0zM2 21.098a1 1 0 1 1-2 0V19a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2.1a1 1 0 1 1-2 0v-2.1a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v2.1zM15.8 2.08a1 1 0 0 1 .398-1.96 6.001 6.001 0 0 1 0 11.76 1 1 0 1 1-.397-1.96 4.002 4.002 0 0 0 0-7.84zM19 16a1 1 0 1 1 0-2 5 5 0 0 1 5 5v2.098a1 1 0 1 1-2 0V19a3 3 0 0 0-3-3z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
