import { lazy } from 'react';
import DashboardIcon from '../assets/icons/DashboardIcon';
import HomeworkIcon from '../assets/icons/HomeworkIcon';
import ResourcesIcon from '../assets/icons/ResourcesIcon';
import ChatIcon from '../assets/icons/ChatIcon';
import GroupsIcon from '../assets/icons/GroupsIcon';
import UsersIcon from '../assets/icons/UsersIcon';
import PaymentIcon from '../assets/icons/paymentIcon';

// Lazy load components
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const Homework = lazy(() => import('../pages/Homework/Homework'));
const Moderators = lazy(() => import('../pages/Moderators/Moderators'));
const Teachers = lazy(() => import('../pages/Users/Pages/Teachers/Teachers'));
const Students = lazy(() => import('../pages/Users/Pages/Students/Students'));
const Groups = lazy(() => import('../pages/Groups/Groups'));
const ComingSoon = lazy(() => import('../pages/ComingSoon/ComingSoon'));
const Resources = lazy(() => import('../pages/Resources/Resources'));

// Roles
export type UserRole = 'admin' | 'teacher' | 'student';

export const routesConfig: Record<
  string,
  {
    name: string;
    path: string;
    component: React.ComponentType;
    icon: any;
    roles: UserRole[];
    children?: {
      name: string;
      path: string;
      component: React.ComponentType;
      roles: UserRole[];
    }[];
  }
> = {
  dashboard: {
    name: 'Dashboard',
    path: '/',
    component: Dashboard,
    icon: DashboardIcon,
    roles: ['admin', 'teacher', 'student'] as UserRole[],
  },
  users: {
    name: 'Users',
    path: '/users',
    component: Dashboard,
    icon: UsersIcon,
    roles: ['admin'] as UserRole[],
    children: [
      {
        name: 'Teachers',
        path: '/users/teachers',
        component: Teachers,
        roles: ['admin'] as UserRole[],
      },
      {
        name: 'Students',
        path: '/users/students',
        component: Students,
        roles: ['admin'] as UserRole[],
      },
    ],
  },
  chat: {
    name: 'Chat',
    path: '/chat',
    component: ComingSoon,
    icon: ChatIcon,
    roles: ['admin', 'teacher', 'student'] as UserRole[],
  },
  homework: {
    name: 'Homework',
    path: '/homework',
    component: Homework,
    icon: HomeworkIcon,
    roles: ['teacher', 'student', 'admin'] as UserRole[],
  },
  resources: {
    name: 'Resources',
    path: '/resources',
    component: Resources,
    icon: ResourcesIcon,
    roles: ['admin'] as UserRole[],
    children: [
      {
        name: 'Resources',
        path: '/resources/resources',
        component: Resources,
        roles: ['admin'] as UserRole[],
      },
      {
        name: 'Archive',
        path: '/resources/archive',
        component: Homework,
        roles: ['admin'] as UserRole[],
      },
    ],
  },
  moderators: {
    name: 'Moderators',
    path: '/moderators',
    component: Moderators,
    icon: UsersIcon,
    roles: ['admin'] as UserRole[],
  },
  groups: {
    name: 'Groups',
    path: '/groups',
    component: Groups,
    icon: GroupsIcon,
    roles: ['admin', 'teacher', 'student'] as UserRole[],
  },
  payment: {
    name: 'Payment Method',
    path: '/payment',
    component: ComingSoon,
    icon: PaymentIcon,
    roles: ['admin', 'teacher', 'student'] as UserRole[],
  },
};
