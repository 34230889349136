import { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      const metrics = [getCLS, getFID, getFCP, getLCP, getTTFB].filter(Boolean);
      metrics.forEach((fn) => fn(onPerfEntry));
    });
  }
};

export default reportWebVitals;
