import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512.001 512.001"
    {...props}
  >
    <path d="M435.2 51.201v-25.6c0-14.14-11.46-25.6-25.6-25.6H51.2c-14.14 0-25.6 11.46-25.6 25.6v38.4H12.8c-7.074 0-12.8 5.726-12.8 12.8 0 7.074 5.726 12.8 12.8 12.8h12.8v25.6H12.8c-7.074 0-12.8 5.726-12.8 12.8s5.726 12.8 12.8 12.8h12.8v25.6H12.8c-7.074 0-12.8 5.726-12.8 12.8s5.726 12.8 12.8 12.8h12.8v25.6H12.8c-7.074 0-12.8 5.726-12.8 12.8 0 7.074 5.726 12.8 12.8 12.8h12.8v25.6H12.8c-7.074 0-12.8 5.726-12.8 12.8 0 7.074 5.726 12.8 12.8 12.8h12.8v25.6H12.8c-7.074 0-12.8 5.726-12.8 12.8 0 7.074 5.726 12.8 12.8 12.8h12.8v25.6H12.8c-7.074 0-12.8 5.726-12.8 12.8 0 7.074 5.726 12.8 12.8 12.8h12.8v25.6H12.8c-7.074 0-12.8 5.726-12.8 12.8 0 7.074 5.726 12.8 12.8 12.8h12.8v38.4c0 14.14 11.46 25.6 25.6 25.6h358.4c14.14 0 25.6-11.46 25.6-25.6V238.797l-25.6 25.6V486.4H51.2V448H64c7.074 0 12.8-5.726 12.8-12.8 0-7.074-5.726-12.8-12.8-12.8H51.2v-25.6H64c7.074 0 12.8-5.726 12.8-12.8 0-7.074-5.726-12.8-12.8-12.8H51.2v-25.6H64c7.074 0 12.8-5.726 12.8-12.8 0-7.074-5.726-12.8-12.8-12.8H51.2v-25.6H64c7.074 0 12.8-5.726 12.8-12.8 0-7.074-5.726-12.8-12.8-12.8H51.2v-25.6H64c7.074 0 12.8-5.726 12.8-12.8 0-7.074-5.726-12.8-12.8-12.8H51.2V192H64c7.074 0 12.8-5.726 12.8-12.8s-5.726-12.8-12.8-12.8H51.2v-25.6H64c7.074 0 12.8-5.726 12.8-12.8s-5.726-12.8-12.8-12.8H51.2V89.6H64c7.074 0 12.8-5.726 12.8-12.8C76.8 69.726 71.074 64 64 64H51.2V25.6h358.4v25.6h25.6z" />
    <path d="m504.499 97.101-25.6-25.6C473.907 66.5 467.353 64 460.8 64c-6.554 0-13.107 2.5-18.099 7.501l-5.308 5.299H384a12.824 12.824 0 0 0-9.054 3.746l-76.8 76.8 18.099 18.099 73.054-73.045h22.494L212.301 301.901a25.496 25.496 0 0 0-4.796 6.656l-25.6 51.2a25.595 25.595 0 0 0 4.796 29.551 25.595 25.595 0 0 0 18.108 7.501c3.883 0 7.799-.887 11.443-2.705l51.2-25.6a25.63 25.63 0 0 0 6.656-4.796l230.4-230.4c9.992-10.001 9.992-26.214-.009-36.207zM204.8 371.201l19.567-39.134 19.567 19.558-39.134 19.576zm60.254-34.654-25.6-25.6L460.8 89.601l25.6 25.6-221.346 221.346z" />
  </svg>
);
export default SvgComponent;
