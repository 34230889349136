import React from 'react';
import s from './Header.module.scss';
import NotificationIcon from '../../assets/icons/NotificationIcon';
import UserIcon from '../../assets/icons/UserIcon';
import classNames from 'classnames';

const Header = () => {
  return (
    <header className={s.wrapper}>
      <h1 className={s.title}>Dashboard</h1>
      <div className={s.notification}>
        <div className={s.notification__button}>
          <NotificationIcon />
        </div>
      </div>
      <div className={s.user}>
        <div className={s.user__icon}>
          <UserIcon />
        </div>
        <div className={s.user__info}>
          <div className={s.user__name}>Arevik Zadayan</div>
          <div className={s.user__role}>Student</div>
        </div>
        <div className={s.user__dropdown}>
          <a className={s.user__link}>settings</a>
          <a className={classNames(s.user__link, s.logout)}>Log Out</a>
        </div>
      </div>
    </header>
  );
};

export default Header;
